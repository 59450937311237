module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Baksta","short_name":"Baksta","description":"Baksta offers powerful yet easy-to-use solutions that help individuals and businesses to purchase, swap, and sell virtual currencies quickly and securely.","lang":"en","start_url":"/","background_color":"#E5E9F4","theme_color":"#E5E9F4","display":"minimal-ui","icon":"src/icons/logo-h32.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fa000d0809151a589343c0ea89d39c64"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
